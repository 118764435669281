import React from 'react';
import styled from 'styled-components';

import { GuideLink } from '@t/components';
import { InlineBlockLink } from '@/components/common/Link';
import createGA from '@/components/common/createGA';

interface GuideMetaInfoProps {
  metaInfo: string;
  btnLabel: string;
  btnLink: GuideLink;
}

const ItemInfo = styled.div`
  max-width: 1060px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 40px auto 30px;
  color: #777;
  font-size: 14px;

  @media (max-width: 720px) {
    max-width: 640px;
    font-size: 10px;
  }
`;

const MoreLink = styled.span`
  display: inline-block;
  color: #515ce6;
  padding: 0 16px;

  @media (max-width: 720px) {
    display: none;
  }
`;

const IconMoreArrow = styled.i`
  position: absolute;
  top: 4px;
  right: 3px;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.09em solid #515ce6;
  border-top: 0.09em solid #515ce6;
  transform: rotate(45deg);
  font-style: normal;

  @media (max-width: 720px) {
    top: 14px;
  }
`;

const GuideMetaInfo: React.FC<GuideMetaInfoProps> = ({ metaInfo, btnLabel, btnLink }) => {
  const ga = createGA('FE Guide - detail', 'click', 'button', 'to list button');

  return (
    <ItemInfo>
      <span>{metaInfo}</span>
      <InlineBlockLink type="internal" url={btnLink} ga={ga}>
        <MoreLink>
          {btnLabel}
          <IconMoreArrow />
        </MoreLink>
      </InlineBlockLink>
    </ItemInfo>
  );
};

export default GuideMetaInfo;
