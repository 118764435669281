import React, { RefObject, useEffect, useState } from 'react';
import styled from 'styled-components';

import { getScrollPosition } from '@/util';
import { BUTTON_DISPLAY_SCROLL_POSITION_Y, MAX_PAD_WIDTH } from '@/constant';
import { Icon } from '@/components/common/Icon';

interface TopButtonProps {
  isInPost: boolean;
  isDisplay: boolean;
}

const TopButton = styled.button<TopButtonProps>`
  right: 65px;
  bottom: 85px;
  width: 50px;
  height: 50px;
  text-decoration: none;
  cursor: pointer;

  position: ${({ isInPost }) => (isInPost ? 'fixed' : 'absolute')};
  display: ${({ isDisplay }) => (isDisplay ? 'inline-block' : 'none')};

  @media (max-width: 1200px) {
    display: none;
  }
`;

const TopButtonIcon = styled(Icon)`
  width: 50px;
  height: 50px;
`;

const FloatingTopBtn: React.FC<{ content: RefObject<HTMLDivElement> }> = ({ content }) => {
  const [isButtonInPost, setButtonInPost] = useState<boolean>(true);
  const [isDisplay, setDisplay] = useState<boolean>(false);
  const clickTopBtn = () => {
    window.scrollTo(0, 0);
    setDisplay(false);
  };

  useEffect(() => {
    const onScroll = () => {
      const yScrollPosition = getScrollPosition().y;
      const element = content.current!;
      const { offsetTop, scrollHeight } = element;

      setButtonInPost(scrollHeight + offsetTop - window.innerHeight > yScrollPosition);
      setDisplay(yScrollPosition > BUTTON_DISPLAY_SCROLL_POSITION_Y);
    };

    if (window.innerWidth > MAX_PAD_WIDTH) {
      window.addEventListener('scroll', onScroll);
    }

    return () => window.removeEventListener('scroll', onScroll);
  }, [content]);

  return (
    <TopButton isInPost={isButtonInPost} isDisplay={isDisplay} onClick={clickTopBtn}>
      <TopButtonIcon iconType="TOP_BUTTON_ICON" />
    </TopButton>
  );
};

export default FloatingTopBtn;
