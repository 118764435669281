import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { GuideInfo, ProductInfo } from '@t/components';
import { createProductSectionData, replaceInvalidHtml } from '@/util';
import { feGuideUrl } from '@/constant';
import SEO from '@/components/SEO';
import GuideMetaInfo from '@/components/guide/GuideMetaInfo';
import FloatingTopBtn from '@/components/FloatingTopBtn';
import PostContent from '@/components/detail/PostContent';
import SitemapSection from '@/components/main/sitemap/SitemapSection';
import createGA from '@/components/common/createGA';

import '@/query/feGuideQueryFragment';

interface FeGuideDetailProps {
  data: {
    post: {
      frontmatter: GuideInfo;
      html: string;
    };
    allProductsJson: { edges: { node: ProductInfo }[] };
  };
  pageContext: {
    lang: 'ko' | 'en';
  };
  location: {
    host: string;
    pathname: string;
  };
}

const Wrapper = styled.div`
  margin-top: 69px;

  @media (max-width: 720px) {
    margin-top: 56px;
  }
`;

const GuidePost = styled.div`
  position: relative;
  padding-bottom: 70px;
  color: #222;
  font-size: 16px;
  border-bottom: 1px solid #e5e5e5;

  @media (max-width: 720px) {
    padding: 25px 0;
    font-size: 14px;
  }
`;

const ContentContainer = styled.div`
  max-width: 1060px;
  margin: 0 auto;
  border-bottom: 1px solid #e5e5e5;
  padding: 60px 0 70px;
  color: #222;
  font-size: 16px;

  @media (max-width: 1200px) {
    padding: 60px 12.5% 70px;
  }

  @media (max-width: 720px) {
    max-width: 640px;
    padding: 0 20px 35px;
    font-size: 14px;
  }
`;

const Content = styled(PostContent)`
  & li p {
    margin: 0;
  }

  & table {
    width: 100%;
    box-sizing: border-box;
    table-layout: fixed;
    border-collapse: collapse;
    border: 1px;
    border-left-style: hidden;
    border-right-style: hidden;
    font-size: 13px;
  }

  & td,
  & th {
    padding: 13px;
    border: 1px solid #eee;
    color: #222;
  }

  & th {
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #ccc;
    font-weight: 700;
  }

  @media (max-width: 720px) {
    & table {
      font-size: 10px;
      word-break: break-word;
    }
  }
`;

const ContentMetaInfoArea = styled.div`
  max-width: 1060px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 0 12.5%;
  }

  @media (max-width: 720px) {
    max-width: 640px;
    padding: 20px 20px 0;
  }
`;

const FeGuideDetail: React.FC<FeGuideDetailProps> = ({ data, pageContext, location }) => {
  const { frontmatter, html } = data.post;
  const { title, description } = frontmatter;
  const { lang } = pageContext;

  const replacedHtml = replaceInvalidHtml(html, true);
  const url = `${location.host}${location.pathname}`;

  const sitemapData = createProductSectionData(data.allProductsJson);
  const ga = createGA('FE Guide - sitemap', 'click', 'sitemap');

  const content = useRef<HTMLDivElement>(null);

  return (
    <Wrapper>
      <SEO title={title} description={description} url={url} />
      <GuidePost ref={content}>
        <ContentContainer>
          <Content dangerouslySetInnerHTML={{ __html: replacedHtml }} />
        </ContentContainer>
        <ContentMetaInfoArea>
          <GuideMetaInfo
            metaInfo="FE Development Lab"
            btnLabel="Back to list"
            btnLink={feGuideUrl[lang.toUpperCase() as 'KO' | 'EN']}
          />
        </ContentMetaInfoArea>
        <FloatingTopBtn content={content} />
      </GuidePost>
      <SitemapSection items={sitemapData} ga={ga} />
    </Wrapper>
  );
};

export const pageQuery = graphql`
  query ($id: String!) {
    post: markdownRemark(frontmatter: { id: { eq: $id } }) {
      html
      ...feGuideFragment
    }

    # query for each section
    ...allProductsJson
  }
`;

export default FeGuideDetail;
