import styled from 'styled-components';

const PostContent = styled.div`
  & h1,
  & h2,
  & h3,
  & h4 {
    display: block;
    font-weight: bold;
    color: #222;
  }

  & h1 {
    font-size: 38px;
    margin: 0;
  }

  & h2 {
    font-size: 30px;
  }

  & h3 {
    font-size: 20px;
    color: #555;
  }

  & h4 {
    font-size: 18px;
    color: #666;
  }

  & h5 {
    font-size: 16px;
  }

  & ul {
    padding-left: 15px;
  }

  & li {
    color: #555;
    font-size: 16px;
    line-height: 30px;
  }

  & p {
    color: #222;
    line-height: 26px;
    margin: 20px 0 10px;
  }

  & strong {
    font-weight: bold;
  }

  & blockquote {
    margin: 0;
    padding: 5px 20px;
    border-left: 5px solid #8b8989;
    background-color: #f4f4f4;
  }

  & blockquote p {
    margin: 0;
  }

  & a {
    text-decoration: none;
    color: #515ce6;
    word-break: break-word;
  }

  & .hash-tag {
    color: #555555;
  }

  & .hash-tag:hover {
    color: #515ce6;
  }

  & .tui-language-text {
    font-weight: normal;
    color: #222222;
  }

  & strong .tui-language-text {
    font-weight: bold;
  }

  & img {
    max-width: 100%;
  }

  & .monthly-title-img {
    width: auto;
    height: 30px;
  }

  & .monthly-title-img.descender {
    width: auto;
    height: 32px;
  }

  & .tags a {
    text-decoration: none;
  }

  & hr:first-child {
    border-top: 1px solid #ddd;
  }

  & hr {
    border-top: 1px solid #e5e5e5;
    margin: 50px 0;
  }

  & .hash-tag {
    margin-top: 0;
    margin-bottom: 0;
  }

  & table {
    width: 100%;
    box-sizing: border-box;
    border-collapse: collapse;
    border: 1px;
    border-left-style: hidden;
    border-right-style: hidden;
    font-size: 16px;
    margin: 20px 0;
  }

  & table img {
    vertical-align: top;
    max-width: 100%;
  }

  & td,
  & th {
    padding: 13px;
    border: 1px solid #eee;
    color: #222;
    text-align: center;
  }

  & th {
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
  }

  @media (max-width: 720px) {
    & hr {
      border: 1px solid #ddd;
      margin: 25px 0;
    }

    & p {
      font-size: 14px;
      line-height: 2;
      margin: 10px 0 5px;
      word-break: break-word;
    }

    & h1 {
      font-size: 24px;
      line-height: 1.5;
    }

    & h2 {
      font-size: 17px;
      line-height: 1.5;
    }

    & h3 {
      font-size: 15px;
      line-height: 1.5;
    }

    & h4 {
      font-size: 14px;
      line-height: 1.5;
    }

    & h5 {
      font-size: 13px;
      line-height: 1.5;
    }

    & li {
      font-size: 14px;
      line-height: 2;

      & p {
        font-size: 14px;
      }
    }

    & img {
      max-width: 100%;
    }

    & .tui-language-javascript {
      word-break: break-all;
    }

    & table {
      font-size: 14px;
      margin: 20px 0;
    }
  }
`;

export default PostContent;
